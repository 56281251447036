<template>
    <div>
        <modal
            v-if="openModalWarningFiscal"
            :message="getMessageFiscalAlert()"
            title="Deseja continuar?"
            check-message="Li e estou ciente"
            is-check-confirm
            :confirm="confirmAlertPlanFiscal"
            :cancel="setCancelModalWarningFiscal"
        />
        <checkout-header :back-button-action="isDesktop ? false : backBtnAction" class="-mb-4">
            <template v-if="!isDesktop" v-slot:back> {{ $t("checkout.back_init") }} </template>
            <template v-slot:header>
                <template>
                    <span data-cy="choose-wizard_control-text">
                        1 {{ $t("checkout.of") }} 2 &mdash;
                    </span>
                </template>
                <strong data-cy="choose-wizard_control_choose-text">
                    {{ $t("checkout.chose_plan") }}</strong
                >
            </template>
        </checkout-header>
        <div class="flex flex-wrap">
            <div v-if="getPlans" class="inner-container">
                <div v-for="plan in getPlans" :key="plan.id" @click="gtmEvent(plan)">
                    <list-item
                        :value="plan.name"
                        :line1="line1(plan.name)"
                        :line2="line2(plan.price, plan.recurrencePeriod)"
                        :disabled="isCurrentPlan({ id: plan.id, priceId: plan.priceId, name: plan.name })"
                        :data-cy="`choose-${plan.name}-list`"
                        @click.native="setPlan(plan)"
                        :selected="selectedPlan === plan.name"
                        :ref="plan.name"
                    />
                </div>
            </div>
        </div>
        <div class="advance-btn justify-center align-middle">
            <submit-button-default
                style="height: 73px; max-width: 100%;"
                :text="$t('advance')"
                data-cy="checkout-submit1-btn"
                :disabled="!isPlanSelected"
                @click.native="nextStep(); eventGtm('custom.nextMigration')"
                :loading="loading"
            />
        </div>
    </div>
</template>

<script>
import {
    HOME_VIEW,
    MIGRATION_CONFIRMATION_VIEW,
    MIGRATION_PLAN_VIEW,
} from "@/router/constants";
import {
    CHANGE_CHOSEN_PLAN,
    GET_PLAN,
    GET_PLANS,
} from "../store/constants/checkout";
import { mapGetters, mapActions } from "vuex";
import CheckoutHeader from "@/components/Checkout/Header.vue";
import ListItem from "@/components/Checkout/ListItem.vue";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import Modal from "@/components/Modal.vue";
import { AccessOrigin, defaultPlansNames } from "@/constants";
import { STORE, STORE_MODULE } from "@/store/constants/store";
import { MIGRATION_MODULE } from "@/store/constants/migration";
import * as config from "../constants";

export default {
    name: MIGRATION_PLAN_VIEW,
    components: {
        CheckoutHeader,
        ListItem,
        SubmitButtonDefault,
        Modal,
    },
    data() {
        return {
            selectedPlan: null,
            openModalWarningFiscal: false,
            loading: false,
            planTrial: null,
            plansName: config.PlansdefaultNames,
        };
    },
    computed: {
        ...mapGetters({
            getPlan: `${MIGRATION_MODULE}/${GET_PLAN}`,
            getPlans: `${MIGRATION_MODULE}/${GET_PLANS}`,
            getStore: `${STORE_MODULE}/${STORE}`,
            lang: `language`,
            accessOrigin: "accessOrigin",
            isDesktop: `isDesktop`,
            eventGtm: 'eventGtm'
        }),

        isPlanSelected() {
            if ((this.getPlan?.name || this.planTrial?.name) !== this.selectedPlan && this.selectedPlan !== defaultPlansNames.FISCAL) {
                return this.selectedPlan
            } else {
                return false
            }
        },
    },
    async mounted() {
        this.selectedPlan = this.getPlan?.name;
    },
    async created() {
        this.loading = true;
        if (!this.getPlans) {
            try {
                await this.getAllPlansAction({
                    lang: this.$store.getters['store/store'].billingAddress.country,
                    actualPlanId: this.getStore.store.priceId ?? this.getStore.planServiceId,
                    current_recurrence: this.getStore.subscription.current_recurrence,
                    price_id: this.getStore.subscription.price_id
                });
            }
            catch(error) {
                const text = this.$t(
                "migration.migrationError"
                ) + " " + this.$t(
                "migration.suport"
                )
                this.errorHandling(error, text);
            }
        }
        this.loading = false;
        if(!this.getPlan && this.getStore.subscription.is_trial){
            this.planTrial = this.getPlans.filter(item => item.name == this.plansName[this.getStore.subscription?.license_type] && item.recurrencePeriod == 1 )[0]
            this.selectedPlan = this.planTrial.name;
        }
    },
    watch: {
        selectedPlan(newPlan) {
            if ((this.getPlan || this.planTrial) != newPlan && newPlan === defaultPlansNames.FISCAL) {
                this.openModalWarningFiscal = true;
            }
        },
    },
    methods: {
        ...mapActions({
            changePlanAction: `${MIGRATION_MODULE}/${CHANGE_CHOSEN_PLAN}`,
            getAllPlansAction: `${MIGRATION_MODULE}/${GET_PLANS}`,
        }),
        backBtnAction() {
            return this.$router.push({
                name: HOME_VIEW,
            });
        },
        getDefaultPlanName(plan) {
            const planDefault = this.$t("plan_default_name")[plan];
            if (!planDefault) {
                return plan;
            }
            return planDefault;
        },
        isCurrentPlan(ids) {
            const { id, priceId, name } = ids
            const planId = this.getStore?.store.priceId ?? this.getStore?.planServiceId
            return planId === id || planId === priceId || this.plansName[this.getStore.subscription?.license_type] == name;
        },
        preNextStep() {
            if ((this.getPlan || this.planTrial) != this.selectedPlan && this.selectedPlan === defaultPlansNames.FISCAL) {
                this.openModalWarningFiscal = true;
            } else {
                this.nextStep();
            }
        },

        async nextStep() {
            return this.$router.push({
                name: MIGRATION_CONFIRMATION_VIEW,
            });
        },
        line1(plan) {
            return `${this.$t("plan")} <strong> ${this.getDefaultPlanName(
                plan
            )} </strong>`;
        },
        line2(price, recurrence) {
            const recurrences = {
                1: "recurrences_freq_num.1",
                6: "recurrences_freq_num.6",
                12: "recurrences_freq_num.12",
            };
            return `${this.$t("currency.sign")} ${price} <small class='text-cool-gray-500'>${this.$t(
                recurrences[recurrence]
            )}</small>`;
        },
        setPlan(plan) {
            if (!this.isCurrentPlan(plan.priceId) || plan != this.planTrial) {
                this.changePlanAction(plan);
                this.selectedPlan = plan.name;  
            } 
        },
        getMessageFiscalAlert() {
            if (!this.accessOrigin !== AccessOrigin.DESKTOP) {
                return this.$t("checkout.fiscal_warning");
            }
            this.setAlertFiscalPlan();
            this.$t("checkout.fiscal_warning");
        },
        setAlertFiscalPlan() {
            alert("@@planofiscal:https://www.programanex.com.br/nota-fiscal");
        },
        confirmAlertPlanFiscal() {
            this.openModalWarningFiscal = false;
            this.nextStep();
        },
        setCancelModalWarningFiscal() {
            this.openModalWarningFiscal = false;
        },
        errorHandling(error, message) {
            this.$fire({
                text: message,
                type: "error",
                timer: 6000,
                customClass: "alert-error-1",
            }).then((result) => {
                 if (result.isConfirmed) {
                    window.location.reload();
                }
                setInterval(window.location.reload(), 6000);
            });
            throw new Error(JSON.stringify(error));
        },
        gtmEvent(plan) {
            if(this.getPlan){
                const event = this.getPlan.price > plan.price ? "downSell" : "upSell"
                this.eventGtm(event)
            }
        }
    },
};
</script>

<style lang="scss">
.submit-label {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.advance-btn {
    width: 100%;
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
}
</style>
