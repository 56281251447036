var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-modal-2",
    {
      attrs: {
        name: "modal-1",
        "header-options": {
          title: _vm.title,
        },
        "modal-size": "md",
        "footer-options": {
          btn1: "Cancelar",
          btn2: "Confirmar",
          btn2Style: {
            opacity: !_vm.isCheck && _vm.isCheckConfirm ? "0.2" : "1",
            backgroundColor: "#0099f9",
          },
          btn2OnClick: () => {
            _vm.clickState()
          },
          btn1OnClick: () => {
            _vm.cancel()
          },
        },
      },
      on: { "on-close": _vm.close },
    },
    [
      _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } }),
      _c("div", { staticClass: "items-center flex" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.isCheck,
              expression: "isCheck",
            },
          ],
          attrs: { id: "checkModal", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.isCheck)
              ? _vm._i(_vm.isCheck, null) > -1
              : _vm.isCheck,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.isCheck,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.isCheck = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.isCheck = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.isCheck = $$c
              }
            },
          },
        }),
        _c("label", { staticClass: "ml-2", attrs: { for: "checkModal" } }, [
          _vm._v(_vm._s(_vm.checkMessage)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }