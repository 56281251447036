<template>
    <!-- eslint-disable vue/no-v-html -->
    <vue-modal-2
        name="modal-1"
        :header-options="{
            title: title,
        }"
        modal-size="md"
        :footer-options="{
            btn1: 'Cancelar',
            btn2: 'Confirmar',
            btn2Style: {
                opacity: !isCheck && isCheckConfirm ? '0.2' : '1',
                backgroundColor: '#0099f9',
            },
            btn2OnClick: () => {
                clickState();
            },
            btn1OnClick: () => {
                cancel();
            },
        }"
        @on-close="close"
    >
        <span v-html="message" />
        <div class="items-center flex">
            <input id="checkModal" v-model="isCheck" type="checkbox" />
            <label for="checkModal" class="ml-2">{{ checkMessage }}</label>
        </div>
    </vue-modal-2>
    <!--eslint-enable-->
</template>
<script>
export default {
    props: {
        message: {
            type: String,
            default: "",
            required: true,
        },
        title: {
            type: String,
            default: "",
            required: true,
        },
        confirm: {
            type: Function,
            default: null,
            required: true,
        },
        cancel: {
            type: Function,
            default: null,
            required: true,
        },
        isCheckConfirm: {
            type: Boolean,
            default: false,
            required: false,
        },
        checkMessage: {
            type: String,
            default: "",
            required: false,
        },
    },
    data() {
        return {
            isCheck: false,
        };
    },
    mounted() {
        this.open();
    },
    methods: {
        clickState() {
            if (this.isCheckConfirm) {
                if (this.isCheck) {
                    return this.confirm();
                }
                return null;
            }
            return this.confirm();
        },
        open() {
            this.$vm2.open("modal-1");
        },
        close() {
            this.cancel();
        },
    },
};
</script>
<style>
.vm2__header {
    @apply font-bold text-lg;
}
.vm2_body {
    @apply text-sm pl-5 pr-4;
}
</style>
